import * as Elements from "../../presentation"
import React, { useContext ,useState,useEffect, useReducer} from "react";
import AccountContext from "../../../context/accounts/accountContext";
import IstavrityContext from "../../../context/istavrity/istavrityContext";
import SpinnerContext from "../../../context/spinner/spinnerContext";
import Enums from "../../../enums/Enums"


export const OnePageProfile = (props) => {
  const account= props.history.location.state;
  const {
   _id
  } =account
  const initState={
    name:"",
    address:{},
    email:"",
    phone:"",
    fcnum:"",
    accountLocked:false,
    members:[]
  }
  const [state, setState]=useState(initState);
  const {
    name,
    address,
    email,
    phone,
    fcnum,
    accountLocked,
    members
  }=state;

  useEffect(()=>{
    const loadUser=async(_id)=>{
      startSpinner();
      let res= await getUserById(_id);
      setState(res);
      stopSpinner();
    }
    loadUser(_id);   
  },[])

  const {line1="", line2="", suburb="", state: region="", postcode=""}=address;
  const accountContext = useContext(AccountContext);
  const istavrityContext = useContext(IstavrityContext);
  const spinnerContext = useContext(SpinnerContext);

  const { impersonate,changeAccountStatus , getUserById} = accountContext;
  const {startSpinner, stopSpinner}= spinnerContext;

  const impersonateUser = async (e) => {
    await impersonate(email);
    istavrityContext.clearDeposits();
    props.history.push("/home");
    //window.location.reload();
  };

  const lockUnlockUser=async()=>{
    startSpinner();
    try {
      let res= await changeAccountStatus({fcnum,status:!accountLocked});
      window.alert(res.data.data.msg);
      setState({...state, accountLocked:!accountLocked})
    } catch (error) {
      window.alert(error.response.data.msg)
     // setAlert({type:"danger",msg:error.response.data.msg})
    }
    stopSpinner();
  }

  return (
    <div>
      <br></br>
      <button
        className=" uk-button uk-button-secondary uk-button-link  uk-text-large uk-text-underline uk-align-right"
        onClick={impersonateUser}
      >
        IMPERSONATE MEMBER
      </button>
      <div>
        <h4 className="uk-text-lead uk-text-bold uk-text-center uk-padding-large uk-margin-top">
          <u>MEMBER PROFILE</u>
        </h4>
      </div>

      <div className="uk-grid uk-grid-divider uk-margin-top uk-child-width-1-2@s uk-padding-large uk-margin-top uk-padding-remove-bottom">
        <div>
          <span
            className="uk-margin-small-right "
            uk-icon="icon: user;ratio: 1.5"
          ></span>
          <span className="uk-text- uk-text-large">{name}</span>
        </div>
        <div>
          <span
            className="uk-margin-small-right"
            uk-icon="icon: mail ;ratio: 1.5"
          ></span>
          <span className="uk-text- uk-text-large">{email}</span>
        </div>
        <div>
          <span className="uk-text uk-text-bold uk-text-large">Family Code:</span>
          <span className="uk-text uk-text-large">{fcnum}</span>
        </div>
        <div>
          <span className="uk-text uk-text-bold uk-text-large">Phone:</span>
          <span className="uk-text- uk-text-large">{phone}</span>
        </div>
        <div className="uk-margin">
          <span className="uk-text uk-text-bold uk-text-large">Address:</span>
          <span className="uk-text- uk-text-large">{line1},{line2},{suburb},{region},{postcode}</span>
        </div>
        <div className="uk-margin">
          <span className="uk-text uk-text-bold uk-text-large">Account Status:</span>
          <span className="uk-text- uk-text-large">{accountLocked?"LOCKED":"ACTIVE"}</span>
          <div>
          <Elements.Button
            name="lockunlockstatus"
            register={()=>{}}
            label={accountLocked?"UNLOCK":"LOCK"}
            className={accountLocked?"uk-button uk-button-success":"uk-button uk-button-danger"}
            onClick={lockUnlockUser}
          />
          
          </div>
          
        </div>
      </div>
      <div className="uk-grid uk-grid-divider uk-margin-top uk-child-width-1-2@m uk-padding-large uk-padding-remove-top uk-padding-remove-bottom">

      </div>

      <hr className="uk-divider-icon"></hr>

      <div className="uk-container-expand">
        <table
          id="allaccounts"
          className="uk-table uk-table-striped uk-table-divider"
        >
          <thead>
            <tr>
              <th> Name</th>
              <th> Ritwik Name</th>
              <th> Member Type</th>
            </tr>
          </thead>

          <tbody>
            {members.map((member) => (
              <tr key={member.name}>
                <td className="uk-text uk-text-center">{member.name}</td>
                <td className="uk-text uk-text-center">
                  {member.memberType?member.ritwik.fullName:"NA"}
                </td>
                <td className="uk-text-capitalize">
                  {Enums.MEMBER_TYPES[member.memberType]}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <button
        className="uk-button uk-button-primary uk-align-center"
        onClick={() => props.history.goBack()}
      >
        BACK
      </button>
    </div>
  );
};



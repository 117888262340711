import React, { useState, useContext } from "react";
import ThreeYearReport from "./3YearReport";
import MiscCodes from "./Misccodes";
import { CountryDropdown } from "react-country-region-selector";
import AccountContext from "../../context/accounts/accountContext";

const Administration = (props) => {

  const accountContext = useContext(AccountContext);
  const { getWhitelistedCountries } = accountContext;
  const initialState = {
    showMiscCodeDiv: true,
    show3YearPlanDiv: false,
    country: "Australia",
  };

  const [state, setState] = useState(initialState);
  const { showMiscCodeDiv, show3YearPlanDiv, country } = state;
  const onSelectDiv = (e) => {
    console.log(e.target.name);
    let newState = {
      showMiscCodeDiv: false,
      show3YearPlanDiv: false,
    };
    switch (e.target.name) {
      case "showMiscCodeDiv":
        newState.showMiscCodeDiv = true;
        break;
      case "show3YearPlanDiv":
        newState.show3YearPlanDiv = true;
        break;
    }
    console.log(JSON.stringify(newState));
    setState({ state,...newState });
  };

  const selectCountry = (val) => {
    setState({ ...state, country: val });
  };

  return (
    <div className="uk-container">
      <ul uk-tab="true">
        <li className="uk-active">
          <a name="showMiscCodeDiv" onClick={onSelectDiv}>
            {" "}
            MISC CODES
          </a>
        </li>
        |
        <li>
          <a name="show3YearPlanDiv" onClick={onSelectDiv}>
            3 Year Report
          </a>
        </li>
      </ul>
      <div>
      <span className="uk-text-bold">Select Country:</span>
            <CountryDropdown
              name="country"
              whitelist={getWhitelistedCountries()}
              className="uk-select uk-text"
              value={country}
              onChange={(val) => selectCountry(val)}
              required={true}
            />
      </div>

      <div className="uk-container ">
        {show3YearPlanDiv && (
          <div id="3yearreport">
            <ThreeYearReport 
            country={country}
            />
          </div>
        )}

        {showMiscCodeDiv && (
          <div id="misccodes">
            <MiscCodes props={props}
            country={country}
            />
          </div>
        )}
      </div>
    </div>
  );
};
export default Administration;

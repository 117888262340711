import React, { useState } from 'react'
import { ShowMembers } from './ShowMembers'
import { AddMember } from '../forms/AddMember'

export const AddAndViewMembers = ({ members, addMember, deleteMember, editMember }) => {
 // loadMembers();
 const onEdit=(member)=>{
   console.log("on edit member")
   setMember(member);
 }
 const onResetForm=()=>{
  console.log("on reset member")
  setMember({ name: "" });
}
  const [member, setMember] = useState({ name: "" })
  return (
    <div uk-grid="true" className="uk-grid-divider uk-child-width-1-2@s">
      <div>
        <div id="updateform" className="uk-card uk-card-small">
          <div className="uk-card-header uk-flex uk-flex-center">
            <h3 className="uk-card-title uk-text-lead uk-flex-center">
              <u>{Object.keys(member).length==1?"Add ":"Edit "}Family Member</u>
            </h3>
          </div>
          <div className=" uk-card-body uk-background-cb-2">
            <AddMember
              addMember={addMember}
              setMember={member}
              onResetForm={onResetForm}
            />
          </div>
        </div>
      </div>

      <div className="uk-card uk-card-small">
        <ShowMembers
          members={members}
          onDelete={deleteMember}
          onEdit={onEdit}
        />
      </div>
    </div>
  )
}

import React from 'react'
import MemberItem from "./MemberItem"

export const ShowMembers = ({ members,onDelete ,onEdit}) => {
    return (

        <div className="uk-card uk-card-small">
            <div className="uk-card-header uk-flex uk-flex-center">
                <h3 className="uk-card-title uk-text-lead uk-flex-center">
                    <u> Family Members</u>
                </h3>
            </div>
            <div className=" uk-card-body" id="memberList">
                {members.map((member) => (
                    <MemberItem
                        key={member._id}
                        member={member}
                        onDelete={onDelete}
                        onEdit={onEdit}
                    ></MemberItem>
                ))}
            </div>
        </div>
    )
}
ShowMembers.defaultProps={
    members:[],
    onEdit:(()=>{})
}
import tapIcon from '../../img/tapIcon.png'
import React from 'react'

const CardClickWithIcon = ({ contents, action }) => {
    return (
        <>
            {contents.map(({ title, description }) => (
                < div key={title}
                    className="uk-card uk-card-default uk-card-body uk-margin uk-border-rounded uk-box-shadow-large uk-button uk-border"
                    onClick={() => action(title.toLowerCase())}
                >
                    <div className="uk-card-title uk-background-muted ">
                        <h3 >{title}</h3>
                    </div>

                    <div className='uk-card-footer uk-text-small'>{description}
                    </div>
                    <img src={tapIcon} height="50" width="50" />
                </div>
            ))
            }
        </>
    )
}

export default CardClickWithIcon
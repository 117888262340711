import React, { useContext, useEffect } from 'react'
import AccountContext from '../../context/accounts/accountContext';
import AlertContext from "../../context/alert/alertContext";
import SpinnerContext from "../../context/spinner/spinnerContext";
import UserAccounts from './admin/UserAccounts';
import ListAdmins from '../superadmin/ListAdmins';
import ENUMS from "../../enums/Enums"
import { MemberProfile, AddAndViewMembers } from './view'
import { clearLocalStorage } from '../../storage';

export const Home = () => {

  const accountContext = useContext(AccountContext);
  const spinnerContext = useContext(SpinnerContext);
  const alertContext = useContext(AlertContext);
  const { user, getLoggedOnUserDetails, updateEmail, updateAddress,
     getWhitelistedCountries, members, addMember, deleteMember, 
     getMembers } = accountContext;
  const { setAlert } = alertContext;
  const { startSpinner, stopSpinner } = spinnerContext;
 

  useEffect(() => {
    const populateUserInfo = async () => {
      startSpinner();
      (Object.keys(user).length == 0)
      // !user
      &&
       await getLoggedOnUserDetails();
      (user.role == ENUMS.ACCOUNT_TYPE.MEMBER) && (members.length == 0) && await getMembers();

      stopSpinner();
    }
    populateUserInfo();
  }, [user, members])

  const showprofileFields = (user) => {
    const { address = {},role, ...rest } = user
    switch (role) {
      case ENUMS.ACCOUNT_TYPE.MEMBER:
        return {  ...rest, address: formAddress(address),role };

      // case ENUMS.ACCOUNT_TYPE.COUNTRY_ADMIN:
      //   return { role: "ADMIN", country, email };

      // case ENUMS.ACCOUNT_TYPE.SUPER_ADMIN:
      //   return { role: "SUPER ADMIN", email };
      default:
        return user

    }
  }


  const editMember=(member)=>{
    console.log(member);
  }

  const formAddress = (address) => {
    let add = "";
    Object.values(address).map(v => {
     !!v &&( add += v + ", ");
    })
    return add.trim().slice(0, -1);
  }
  const addNewMember = async (data) => {
    startSpinner();
    await addMember(data);
    stopSpinner();
  }

  const deleteExistingMember = async (data) => {
    let result = window.confirm("Are you sure to delete this member?")
    if (result) {
      startSpinner();
      let resp = await deleteMember(data);
      stopSpinner();
      setAlert(resp)
    }

  }

  const setEmail = async (data) => {
    let result = window.confirm("Are you sure this is the correct email: ?"+data);
    if (result){
      startSpinner();
      let res = await updateEmail(data);
      stopSpinner();
      setAlert(res)
    }
    
  }

  const setAdress = async (data) => {
    startSpinner();
    let res = await updateAddress(data);
    stopSpinner();
    window.alert(res.msg);
    if (res.type == "success") {
      clearLocalStorage();
      window.location.reload();
    }
  }


  return (
    <>
      <MemberProfile
        user={showprofileFields(user)}
        updateEmail={setEmail}
        updateAddress={setAdress}
        countries={getWhitelistedCountries()}
      />

      <hr className="uk-divider-icon"></hr>
      {user.role == ENUMS.ACCOUNT_TYPE.MEMBER && <AddAndViewMembers
        addMember={addNewMember}
        deleteMember={deleteExistingMember}
        members={members}
        editMember={editMember}
      />}
      {user.role == ENUMS.ACCOUNT_TYPE.COUNTRY_ADMIN && <UserAccounts 
     
      />}
      {user.role == ENUMS.ACCOUNT_TYPE.SUPER_ADMIN && <ListAdmins />}
    </>
  )
}


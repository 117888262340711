class ENUMS{

    MEMBER_TYPES={
        0: "non initiated",
        1: "initiated",
        2: "karmi",
        3: "spr"
    }
    ACCOUNT_TYPE={
        MEMBER:0,
        COUNTRY_ADMIN:1,
        SUPER_ADMIN:2
    }
    API_REQUEST_TYPE={
        GET:"GET",
        POST:"POST",
        PUT:"PUT",
        DELETE:"DELETE"
    }

    DEPOSIT_TYPE={
        istavrity:"istavrity",
        arghya:"arghya",
        pronami:"pronami"
    }

    DEPOSIT_SUBTYPE={
        general:"general",
        karmi:"karmi"
    }
}

export default new ENUMS();
